
export default {
  components: {},
  data() {
    return {
      category: "bime",
    };
  },
  methods: {
    changeCategory(payload) {
      this.category = payload;
    },
  },
};
