
import quote from "./quote.vue";
export default {
  components: { quote },
  data() {
    return {
      solutions: [
        {
          name: "ابرسازمانی",
          content:
            "در این بخش در مورد نحوه استقرار زیرساخت‌هایی ابری برای یک مجموعه یا سازمان تصمیم‌گیری و اجرا می‌شود.",
          icon: "organization-cloud.svg",
          title: "سرویس ابر سازمانی",
          alt: "آیکون سرویس ابر سازمانی "
        },
        {
          name: "تحول دیجیتال",
          content:
            "در این قسمت مهاجرت ابری مجموعه‌ها و انتقال از زیرساخت‌های سنتی به زیرساخت ابری انجام می‌شود.",
          icon: "digital-tahavol.svg",
          title: "مهاجرت سرویس ابری زس",
          alt: "آیکون مهاجرت سرویس ابری زس"
        },
        {
          name: "راهکارهای جامع سازمانی",
          content:
            "کامل‌ترین سرویس‌های ابری «زس» برای هر مجموعه‌ای و در هر اندازه‌ای در این بخش ارائه می‌گردد.",
          icon: "organization-solutions.svg",
          title: "راهکارهای جامع سازمانی",
          alt: "آیکون راهکارهای جامع سازمانی"
        },
        {
          name: "امنیت سازمانی",
          content:
            "لایه‌های محافظتی ابر «زس» در مقابل هر خطر امنیتی که سازمان‌ها را تهدید می‎‌کند در این قسمت قابل ارائه است.",
          icon: "organization-security.svg",
          title: "امنیت سازمانی ابر زس",
          alt: "آیکون امنیت سازمانی ابر زس"
        },
        {
          name: "خدمات تخصصی سازمانی",
          content:
            "از خدمات دوآپس تا نظارت‌های فنی و تخصصی بر کل زیرساخت‌های ارائه شده به سازمان‌ها در این بخش انجام می‌شود.",
          icon: "orgranization-services.svg",
          title: "خدمات تخصصی سازمانی سرویس ابری ابر زس",
          alt: "آیکون خدمات تخصصی سازمانی سرویس ابری ابر زس"
        },
      ],
    };
  },
};
