// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icons/quote.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root[data-v-41b05a7c]{border:2px dashed #ffb500;border-radius:30px;margin:auto;max-width:98%;padding:24px;position:relative}@media only screen and (min-width:960px){#root#root[data-v-41b05a7c]{padding:32px}}#root[data-v-41b05a7c]:before{left:-1%;top:-4%}#root[data-v-41b05a7c]:after,#root[data-v-41b05a7c]:before{background-color:#112c89;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;content:\" \";height:30px;position:absolute;width:30px}#root[data-v-41b05a7c]:after{left:97%;top:93%}#root .panel[data-v-41b05a7c]{background:#fff;border:3px solid #58bcff;border-radius:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
